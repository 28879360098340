import React from "react";
// import styles from "./NotFound.module.scss";

const NotFound = () => {
  return (
    <>
      <p>This page does not exist</p>
    </>
  );
};

export default NotFound;
